import { useState, useEffect, useRef } from 'react';
import isEmpty from 'lodash/isEmpty';
import { gatherFormData } from 'utils/formData/providerFormData';

const useCareNavigatorConfirmation = (formKey, form) => {
  const confirmChangesRef = useRef();

  const { customerAssignmentData: careNavigatorDetails, roles: customerAssignmentRoles } = gatherFormData(
    formKey,
    form
  );

  const customerId = (careNavigatorDetails || {}).customer_id;
  const assignmentInfo = (careNavigatorDetails || {}).assignment_type;

  const [originalRole, setOriginalRole] = useState(assignmentInfo);
  const [originalCustomerId, setOriginalCustomerId] = useState(customerId);
  const [providerRoles, setProviderRoles] = useState(customerAssignmentRoles);

  const [customerChanged, trackCustomerChanged] = useState(false);
  const [cnTypeChanged, trackCnTypeChanged] = useState(false);
  const [cnRoleChanged, trackCnRoleChanged] = useState(false);

  confirmChangesRef.current = cnRoleChanged || cnTypeChanged || customerChanged;

  useEffect(() => {
    trackCnRoleChanged(true);
    setProviderRoles(customerAssignmentRoles);
  }, [customerAssignmentRoles, providerRoles]);

  useEffect(() => {
    if (!isEmpty(careNavigatorDetails) && typeof originalCustomerId !== 'undefined') {
      if (customerId !== originalCustomerId) {
        trackCustomerChanged(true);
      } else {
        trackCustomerChanged(false);
      }
    }

    setOriginalCustomerId((prevCustomer) => (typeof originalCustomerId === 'undefined' ? customerId : prevCustomer));
  }, [customerId, originalCustomerId]);

  useEffect(() => {
    if (!isEmpty(careNavigatorDetails) && typeof originalRole !== 'undefined') {
      if (assignmentInfo !== originalRole) {
        trackCnTypeChanged(true);
      } else {
        trackCnTypeChanged(false);
      }
    }

    setOriginalRole((prevRole) => (typeof prevRole === 'undefined' ? assignmentInfo : prevRole));
  }, [assignmentInfo, originalRole]);

  const confirmationSubtitleText = () => {
    let subtitleText = '';

    if (cnRoleChanged) {
      subtitleText = "Confirm that you would like to change this user's role.";
    } else if (cnTypeChanged) {
      subtitleText = "Confirm that you would like to change this Care Navigator's type.";
    } else if (customerChanged) {
      if (subtitleText.length) {
        subtitleText =
          "Confirm that you would like to change this Care Navigator's type and the company they are assigned to.";
      } else {
        subtitleText = 'Confirm that you would like to change the company this Care Navigator is assigned to.';
      }
    }

    return subtitleText;
  };

  const confirmationMainText = () => {
    let mainText = '';

    if (cnRoleChanged) {
      mainText = '';
    } else if (cnTypeChanged) {
      mainText =
        "You have made changes to this Care Navigator's role that will affect which members can see their calendar.";
    }

    if (customerChanged) {
      if (mainText.length) {
        mainText =
          mainText +
          " This Caregiver's calendar will no longer be viewable to members of their previously assigned company, but they will keep the caseload they currently have.";
      } else {
        mainText =
          "This Caregiver's calendar will no longer be viewable to members of this company, but they will keep the caseload they currently have.";
      }
    }

    return mainText;
  };

  return {
    confirmChangesRef,
    confirmationSubtitleText: confirmationSubtitleText(),
    confirmationMainText: confirmationMainText(),
    setOriginalRole,
    setOriginalCustomerId,
  };
};

export default useCareNavigatorConfirmation;
