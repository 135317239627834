import { theme, ChakraProvider, extendTheme } from '@springcare/sh-component-library';

/**
 * Extend Verdant theme
 * @description: Just do this for non standard components, anything widely used should be added in the component library instead.
 * See all Chakra components on: https://v1.chakra-ui.com/docs/components/overview
 * More info about Verdant in: http://springcare.github.io/component-library
 */
const extendedTheme = extendTheme(theme.cgx, {
  colors: {
    pear: {
      100: '#e8f0b0',
    },
  },
  components: {
    Popover: {
      variants: {
        'full-width': {
          popper: {
            minWidth: '100% !important',
          },
        },
      },
    },
    Spinner: {
      sizes: {
        '2xl': {
          height: '64px',
          width: '64px',
        },
        '3xl': {
          height: '80px',
          width: '80px',
        },
      },
    },
  },
});

const ThemeProvider = ({ children }) => {
  return <ChakraProvider theme={extendedTheme}>{children}</ChakraProvider>;
};

export default ThemeProvider;
