const routes = {
  PublicIndex: {
    to: '/apps/Public/_home',
    as: '/',
  },
  ForgotPassword: {
    to: '/apps/Public/forgot_password',
    as: '/forgot_password',
  },
  ResendConfirmationEmail: {
    to: '/apps/Public/resend_confirmation_email',
    as: '/resend_confirmation_email',
  },
  ResetPassword: {
    to: '/apps/Public/reset_password',
    as: '/reset_password',
  },
  SignIn: {
    to: '/apps/Public/sign_in',
    as: '/sign_in',
  },
  Logout: {
    to: '/apps/Public/logout',
    as: '/logout',
  },
};

export default routes;
