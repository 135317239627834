import { relayStylePagination } from '@apollo/client/utilities';

export default {
  Query: {
    fields: {
      appointment_nodes: relayStylePagination(),
      member_appointments: relayStylePagination(['member_id', 'starting_after', 'starting_before']),
    },
  },
};
