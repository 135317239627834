import { useQuery } from '@apollo/client';

import { getChannelPartners } from 'operations/queries/channel_partner';

const useChannelPartners = () => {
  return useQuery(getChannelPartners, {
    onError: (error) => console.log(error),
  });
};

export default useChannelPartners;
