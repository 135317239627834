export const t2Languages = [
  {
    label: 'Estonian',
    value: 'Estonian',
  },
  {
    label: 'Croatian',
    value: 'Croatian',
  },
  {
    label: 'Slovenian',
    value: 'Slovenian',
  },
  {
    label: 'Slovak',
    value: 'Slovak',
  },
  {
    label: 'Latvian',
    value: 'Latvian',
  },
  {
    label: 'Sinhala',
    value: 'Sinhala',
  },
  {
    label: 'Ukrainian',
    value: 'Ukrainian',
  },
  {
    label: 'Kazakh',
    value: 'Kazakh',
  },
  {
    label: 'Urdu',
    value: 'Urdu',
  },
  {
    label: 'Armenian',
    value: 'Armenian',
  },
  {
    label: 'Traditional Chinese (Taiwan)',
    value: 'Traditional Chinese (Taiwan)',
  },
  {
    label: 'Traditional Chinese (Hong Kong)',
    value: 'Traditional Chinese (Hong Kong)',
  },
  {
    label: 'Macedonian',
    value: 'Macedonian',
  },
  {
    label: 'Icelandic',
    value: 'Icelandic',
  },
  {
    label: 'Belarusian',
    value: 'Belarusian',
  },
  {
    label: 'Thai',
    value: 'Thai',
  },
  {
    label: 'Bangladeshi',
    value: 'Bangladeshi',
  },
  {
    label: 'Vietnamese',
    value: 'Vietnamese',
  },
];
