import { useRef, useEffect } from 'react';

// Custom hook is for storing the previous version of a state value
// - useful if you need to compare a previous state in order to perform an operation
const useSyncPreviousValue = (value) => {
  const ref = useRef(value);
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export default useSyncPreviousValue;
