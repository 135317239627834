import { gql } from '@apollo/client';

import { trialDetails } from '../../fragments/trial';

const getTrial = gql`
  query getTrial($id: ID!) {
    trial(id: $id) {
      ...trialDetails
      users {
        first_name
        last_name
        email
      }
    }
  }
  ${trialDetails}
`;

export default getTrial;
