//
import { setIn } from 'utils/immutability';

import { MOUNT_TABLE, SET_ACTIVE_KEYS, SET_PER_PAGE } from '../../actions/table/types';

export const initialState = {};

const table = (state = initialState, action) => {
  switch (action.type) {
    case MOUNT_TABLE:
      return setIn([action.tableKey, 'activeKeys'], action.activeKeys, state);

    case SET_ACTIVE_KEYS:
      return setIn([action.tableKey, 'activeKeys'], action.activeKeys, state);

    case SET_PER_PAGE:
      return setIn([action.tableKey, 'perPage'], action.perPage <= 50 ? action.perPage : 20, state);

    default:
      return state;
  }
};

export default table;
