import { gql } from '@apollo/client';

const getChannelPartner = gql`
  query getChannelPartner($id: ID!) {
    channel_partner(id: $id) {
      id
      name
      launched
      active_at
      contract_end_at
      channel_partner_type
      channel_partner_sub_type
      landing_page_url
      sso_client_slug
      customers {
        id
        name
        marketing_name
        number_of_employees
        number_of_dependents
        care_navigation_contract_type
      }
    }
  }
`;

export default getChannelPartner;
