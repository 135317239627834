import { Grid, Flex, Box, SpringLogoIcon } from '@springcare/sh-component-library';

interface ISHHeaderProps {
  isMobileViewport: boolean;
}

export const SHHeaderMaintenance = ({ isMobileViewport }: ISHHeaderProps) => {
  const MobileHeader = () => (
    <Box data-testid="header-mobile" as="nav">
      <Box h={95} p={'32px'}>
        <Flex alignItems="center">
          <SpringLogoIcon boxSize="28px" />
        </Flex>
      </Box>
    </Box>
  );

  const DesktopHeader = () => (
    <Box data-testid="header-desktop" as="nav">
      <Grid templateColumns="repeat(2, 1fr)" gap={0} h={95}>
        <Flex bg="accent-on-base" alignItems="center">
          <Box ps="32px">
            <SpringLogoIcon boxSize="28px" />
          </Box>
        </Flex>
      </Grid>
    </Box>
  );

  return <Box>{isMobileViewport ? <MobileHeader /> : <DesktopHeader />}</Box>;
};
