/**
 * Enums for passing in Appointment Mediums
 * where necessary.
 */

export const AppointmentMedium = Object.freeze({
  Video: 'VIDEO',
  Phone: 'PHONE',
  InPerson: 'IN_PERSON',
});
