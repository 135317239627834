import { v4 as uuidv4, validate as uuidValidate } from 'uuid';

/**
 * Module to handle post-Jupiter telemetry needs for a global session ID to group all requests
 * to our various backends from a single instance of a frontend client. As such, we're less
 * concerned with the lifespan of the "sessions" being tracked than we are with
 * correctly grouping requests within a span in Datadog. Therefore, we leverage localStorage
 * (also for its shared storage between browser tabs) rather than sessionStorage.
 */

const SESSION_ID_KEY = 'ddSpringHealthMagicId';

export const ddGlobalSessionId = {
  getSessionId: () => {
    const currentSessionId = window?.localStorage.getItem(SESSION_ID_KEY);

    if (currentSessionId && uuidValidate(currentSessionId)) {
      return currentSessionId;
    } else {
      const sessionId = uuidv4();

      window?.localStorage.setItem(SESSION_ID_KEY, sessionId);
      return sessionId;
    }
  },
};
