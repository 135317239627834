export const USER_TRACKING_EVENTS = {
  BUTTON_CLICKED: 'Button Clicked',
  LINK_CLICKED: 'Link Clicked',
  FORM_SUBMITTED: 'Form Submitted',
  MODAL_OPENED: 'Modal Opened',
  MODAL_CLOSED: 'Modal Closed',
  NOTIFICATION_VIEWED: 'Notification Viewed',
  FILTER_CHANGE_APPLIED: 'Filter Change Applied',
};

export const APP_TRACKING_EVENTS = {
  PAGE_VIEWED: 'Page Viewed', // DO NOT USE. Page view are logged on router changes in Initialize
};
