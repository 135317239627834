import { API_DOMAIN, ATLAS_API_DOMAIN, AUTH_API_DOMAIN, EHR_URL } from 'constants/api';

import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import envUtils from 'utils/environment';
import routes from 'routes';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useForm,
  Input,
  Radio,
  RadioGroup,
  Flex,
  ChakraAlert as Alert,
  Button,
  ButtonGroup,
} from '@springcare/sh-component-library';

const localhostRotom = 'http://localhost:4400';
const localhostDiglet = 'http://localhost:8081';
const localhostEHR = 'http://localhost:5500';
const localhostAtlas = 'http://localhost:4000';
const perfLabRotom = 'https://app-40260.on-aptible.com';
const perfLabDiglet = 'https://app-40265.on-aptible.com';
const perfLabEHR = 'https://app-47558.on-aptible.com';
const perfLabAtlas = ''; //  Not yet deployed

const codespacesRotom = process.env.CODESPACE_NAME
  ? `https://${process.env.CODESPACE_NAME}-4400.${process.env.GITHUB_CODESPACES_PORT_FORWARDING_DOMAIN}`
  : '';
const codespacesDiglet = process.env.CODESPACE_NAME
  ? `https://${process.env.CODESPACE_NAME}-8081.${process.env.GITHUB_CODESPACES_PORT_FORWARDING_DOMAIN}`
  : '';
const codespacesEhr = process.env.CODESPACE_NAME
  ? `https://${process.env.CODESPACE_NAME}-5500.${process.env.GITHUB_CODESPACES_PORT_FORWARDING_DOMAIN}`
  : '';
const codespacesAtlas = process.env.CODESPACE_NAME
  ? `https://${process.env.CODESPACE_NAME}-4401.${process.env.GITHUB_CODESPACES_PORT_FORWARDING_DOMAIN}`
  : '';

export const MagicEnvModal = ({ isOpen, onClose }) => {
  const router = useRouter();
  const { register, handleSubmit, setValue } = useForm({
    defaultValues: {
      rotom: API_DOMAIN,
      diglet: AUTH_API_DOMAIN,
      ehr: EHR_URL,
      atlas: ATLAS_API_DOMAIN,
    },
  });
  /**
   * NOTE: See https://humanwhocodes.com/blog/2010/01/12/history-of-the-user-agent-string/ for why
   * we can't just check if the UA includes 'Safari'
   */
  const isLocalhostDisabled = !navigator.userAgent.includes('Firefox') && !navigator.userAgent.includes('Chrome');

  const resetEnv = () => {
    localStorage.removeItem('rotom');
    localStorage.removeItem('diglet');
    localStorage.removeItem('ehr');
    localStorage.removeItem('atlas');

    router.replace('/logout');
    onClose();
  };

  const onSubmit = ({ rotom, diglet, ehr, atlas }) => {
    localStorage.setItem('rotom', rotom);
    localStorage.setItem('diglet', diglet);
    localStorage.setItem('ehr', ehr);
    localStorage.setItem('atlas', atlas);

    router.replace('/logout');
    onClose();
  };

  const setEnv = (rotom, diglet, ehr = '', atlas = '') => {
    setValue('rotom', rotom);
    setValue('diglet', diglet);
    setValue('ehr', ehr);
    setValue('atlas', atlas);
  };

  useEffect(() => {
    if (envUtils.redirectShayminHomepageToSignIn) {
      router.replace(routes.SignIn.to, routes.SignIn.as);
    } else if (router?.query?.rotom && router?.query?.diglet) {
      setEnv(decodeURIComponent(`${router.query.rotom}`), decodeURIComponent(`${router.query.diglet}`));
    }
  }, [process.env.APP_ENV]);

  const selectEnv = ({ env }) => {
    switch (env) {
      case 'localhost':
        return setEnv(localhostRotom, localhostDiglet, localhostEHR, localhostAtlas);
      case 'perf-lab':
        return setEnv(perfLabRotom, perfLabDiglet, perfLabEHR, perfLabAtlas);
      case 'codespaces':
        return setEnv(codespacesRotom, codespacesDiglet, codespacesEhr, codespacesAtlas);
      default:
        return setEnv(
          `https://care-api.${env}.springtest.us`,
          `https://auth-api.${env}.springtest.us`,
          `https://ehr-api.${env}.springtest.us`,
          `https://atlas.${env}.springtest.us`
        );
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Magic ENV Toggle</ModalHeader>
        <ModalCloseButton onClick={onClose} />
        <ModalBody>
          {isLocalhostDisabled && (
            <Alert status="error" size="sm" mb={4}>
              Localhost isn't available on this browser. Please switch to Chrome or Firefox for local development
            </Alert>
          )}
          <form onSubmit={handleSubmit(onSubmit)}>
            <RadioGroup name="env" onChange={(e) => selectEnv({ env: e })} mb={4}>
              <Flex justifyContent="space-between">
                <Radio value="local" isDisabled={isLocalhostDisabled}>
                  Local
                </Radio>
                <Radio value="staging">Staging</Radio>
                <Radio value="perflab">Performance Lab</Radio>
                <Radio value="codespaces">Codespaces</Radio>
              </Flex>
            </RadioGroup>
            <Flex direction="column" gap={4}>
              <Input label="Rotom" {...register('rotom')} />
              <Input label="Diglet" {...register('diglet')} />
              <Input label="EHR" {...register('ehr')} />
              <Input label="Atlas" {...register('atlas')} />
            </Flex>
            <ButtonGroup my={4} width="100%" justifyContent="space-between">
              <Button colorScheme="negative" onClick={() => resetEnv()}>
                Reset
              </Button>
              <Button type="submit">Save</Button>
            </ButtonGroup>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
