export const licenses = [
  { label: 'MD', value: 'MD' },
  { label: 'DO', value: 'DO' },
  { label: 'PhD', value: 'PhD' },
  { label: 'PsyD', value: 'PsyD' },
  { label: 'LCSW', value: 'LCSW' },
  { label: 'MSW', value: 'MSW' },
  { label: 'LMSW', value: 'LMSW' },
  { label: 'LPC', value: 'LPC' },
  { label: 'LMFT', value: 'LMFT' },
  { label: 'LMHC', value: 'LMHC' },
  { label: 'LCP', value: 'LCP' },
  { label: 'LCPC', value: 'LCPC' },
  { label: 'LPC-S', value: 'LPC-S' },
  { label: 'LPCC', value: 'LPCC' },
  { label: 'LCMHC', value: 'LCMHC' },
  { label: 'LICSW', value: 'LICSW' },
  { label: 'NP', value: 'NP' },
  { label: 'APRN', value: 'APRN' },
  { label: 'ICF-ACC', value: 'ICF-ACC' },
  { label: 'ICF-PCC', value: 'ICF-PCC' },
  { label: 'ICF-MCC', value: 'ICF-MCC' },
  { label: 'NBC-HWC', value: 'NBC-HWC' },
];
