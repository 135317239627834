/**
 * Enums for provider statuses.
 */

export const ProviderStatus = Object.freeze({
  Pending: 'Pending',
  Submitted: 'Submitted',
  Approved: 'Approved',
  Disabled: 'Disabled',
  Rejected: 'Rejected',
  NoNewPatients: 'No New Patients',
  QualityHold: 'Quality Hold',
  GeneralHold: 'General Hold',
});
