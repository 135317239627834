import getOr from 'lodash/fp/getOr';

export const gatherFormData = (formKey, form) => {
  const formRole = getformRole(formKey, form);
  const isCareNavigator = getIsCareNavigator(formRole);
  const isTherapist = getIsTherapist(formRole);
  const isDedicated = getIsDedicated(formKey, form);
  const isOnSite = getIsOnSite(formKey, form);
  const customerAssignmentData = getCustomerAssignmentData(formKey, form);
  const dedicatedAssignmentData = getDedicatedAssignmentData(formKey, form);
  const cohortsAssignmentData = getCohortIdsData(formKey, form);

  return {
    isCareNavigator,
    formRole,
    customerAssignmentData,
    dedicatedAssignmentData,
    cohortsAssignmentData,
    inPersonSupported: getInPersonSupported(formKey, form),
    showCustomer: getShowCustomer(isCareNavigator, customerAssignmentData),
    isTherapist,
    isDedicated,
    isOnSite,
  };
};

export const getInPersonSupported = (formKey, form) => {
  return getOr('', `${formKey}.data.in_person_supported`, form);
};

export const getVirtualSupported = (formKey, form) => {
  return getOr(null, `${formKey}.data.virtual_supported`, form);
};

export const getIsOnSite = (formKey, form) => {
  return getOr(null, `${formKey}.data.on_site`, form);
};

export const getIsDedicated = (formKey, form) => {
  return getOr(null, `${formKey}.data.dedicated`, form);
};

const getformRole = (formKey, form) => {
  const rolesField = getOr([], `${formKey}.data.roles`, form);
  return rolesField[0] || '';
};

const getIsCareNavigator = (formRole) => {
  return formRole === 'Care Navigator';
};

const getIsTherapist = (formRole) => {
  return formRole === 'Therapist';
};

const getCustomerAssignmentData = (formKey, form) => {
  return getOr({}, `${formKey}.data.care_navigator_customer_assignment`, form);
};

const getDedicatedAssignmentData = (formKey, form) => {
  return getOr({}, `${formKey}.data.dedicated_customer`, form);
};

const getCohortIdsData = (formKey, form) => {
  return getOr([], `${formKey}.data.cohorts`, form);
};

const getShowCustomer = (isCareNavigator, customerAssignmentData) => {
  return (
    isCareNavigator && customerAssignmentData.assignment_type && customerAssignmentData.assignment_type !== 'standard'
  );
};

export const getShowInternational = (formKey, form) => {
  return getOr('', `${formKey}.data.international`, form);
};
