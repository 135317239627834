import curry from 'lodash/fp/curry';
import get from 'lodash/fp/get';
import set from 'lodash/fp/set';
import _unset from 'lodash/fp/unset';

/**
 * Takes a path, an index, and the object to unset.
 * Unsets the property at the path for the object. If an index is provided, unsets the
 * value at that index given the path is an array.
 *
 * @name unset
 * @param {(string | Array<String | Number>)} path
 * @param {Number | null} index
 * @param {Object} obj
 * @returns {Object} Returns a new object with updated value.
 *
 * @example
 * const x = {a: { b: 'remove me!'} };
 * const y = unset('a.b', null, x);
 *
 * console.log(y); // {a: {} }
 *
 * @example
 * const x = {a: { b: ['keep', 'remove'] } }
 * const y = unset('a.b', 1, x);
 *
 * console.log(y); // {a: { b: ['keep'] } }
 */

function curried(path, index, obj) {
  if (index !== null) {
    const arr = get(path, obj);
    if (Array.isArray(arr)) {
      arr.splice(index, 1);
      _unset(path, obj);
      return set(path, arr, obj);
    }
  }
  return _unset(path, obj);
}

const unset = curry(curried);

export default unset;
