import { useQuery } from '@apollo/client';
import { getTrial } from 'operations/queries/trial';

type Trial = {
  id: string;
  child_dependents_allowed: boolean;
  coaching_supported: boolean;
  company_name: string;
  create_passwords: boolean;
  dependents_allowed: boolean;
  end_date: string;
  in_person_supported: boolean;
  moments_supported: boolean;
  start_date: string;
  status: string;
  created_at: string;
  updated_at: string;
  customer_id: string;
  users: Array<{
    first_name: string;
    last_name: string;
    email: string;
  }>;
};

type TrialResult = {
  trial: Trial;
};

const useTrial = (trialId: string) => {
  return useQuery<TrialResult>(getTrial, {
    variables: { id: trialId },
    onError: (error) => console.log(error),
  });
};

export type { Trial };
export default useTrial;
