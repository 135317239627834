import LocalStorageUtils from '../utils/localStorage';
import { ddGlobalSessionId } from '../utils/telemetry';
import { API_BASE } from 'constants/api';

export const SH_MAGIC_ID_HEADER_KEY = 'x-springhealth-magic-id';

export const generateRequestHeaders = () => ({
  ...LocalStorageUtils.getAuthorizationHeader(),
  [SH_MAGIC_ID_HEADER_KEY]: ddGlobalSessionId.getSessionId(),
  'Content-Type': 'application/json',
});

const uploadPlanRate = async (body) => {
  const response = await fetch(`${API_BASE}/upload_plan_rate`, {
    method: 'POST',
    body,
    headers: LocalStorageUtils.getAuthorizationHeader(),
  });

  return response.json();
};

const runReportingIntegrationJob = async (integration_id: number, body) => {
  return fetch(`${API_BASE}/reporting_integration_ad_hoc_runs/${integration_id}`, {
    method: 'POST',
    headers: generateRequestHeaders(),
    body,
  });
};

const submitCensus = async (body) => {
  const response = await fetch(`${API_BASE}/census_ingestions`, {
    method: 'POST',
    headers: LocalStorageUtils.getAuthorizationHeader(),
    body,
  });

  return response.json();
};

export default {
  generateRequestHeaders,
  uploadPlanRate,
  runReportingIntegrationJob,
  submitCensus,
};
