//
// @TODO: fix type issue from https://www.pivotaltracker.com/story/show/153521122

import flow from 'lodash/fp/flow';
import { DateTime } from 'luxon';
import { setIn, unset } from 'utils/immutability';

import * as actions from '../../actions/form/types';

const initialState = {};

const form = (state = initialState, action) => {
  switch (action.type) {
    case actions.INITIALIZE_FORM:
      return flow(
        setIn(`${action.formKey}.data`, action.initialData),
        setIn(`${action.formKey}.meta`, action.meta),
        setIn(`${action.formKey}.meta.updatedAt`, DateTime.local().toISO()),
        setIn(`${action.formKey}.validation`, {}),
        setIn(`${action.formKey}.validation.client`, {}),
        setIn(`${action.formKey}.validation.isValid`, false),
        setIn(`${action.formKey}.theme`, action.theme),
        setIn(`${action.formKey}.initialized`, true),
        setIn(`${action.formKey}.schema`, action.schema)
      )(state);

    case actions.SET_FORM_FIELD:
      return flow(
        setIn(`${action.formKey}.data.${action.fieldKey}`, action.value),
        setIn(`${action.formKey}.meta.updatedAt`, DateTime.local().toISO()),
        setIn(`${action.formKey}.meta.${action.fieldKey}.dirty`, action.dirty),
        setIn(`${action.formKey}.meta.${action.fieldKey}.fieldType`, action.fieldType)
      )(state);

    case actions.SET_FORM_VALIDATION:
      return flow(
        setIn(`${action.formKey}.validation.client`, action.validation),
        setIn(`${action.formKey}.validation.isValid`, action.isValid)
      )(state);

    case actions.CLEAR_FORM:
      return flow(
        setIn(`${action.formKey}.data`, {}),
        setIn(`${action.formKey}.meta`, {}),
        setIn(`${action.formKey}.validation`, {}),
        setIn(`${action.formKey}.initialized`, false)
      )(state);

    case actions.REMOVE_FORM_FIELD:
      return flow(
        unset(`${action.formKey}.data.${action.fieldKey}`, action.index),
        unset(`${action.formKey}.meta.${action.fieldKey}`, action.index)
      )(state);

    case actions.SET_DATEPICKER_DISPLAY_DATE:
      return flow(setIn(`${action.formKey}.meta.${action.fieldKey}.displayDate`, action.value))(state);

    case actions.SET_TYPEAHEAD_SEARCH_VALUE:
      return flow(setIn(`${action.formKey}.meta.${action.fieldKey}.searchValue`, action.value))(state);

    case actions.SET_ADDRESS_VALUE:
      return flow(
        setIn(`${action.formKey}.data.${action.fieldKey}.searchValue`, action.value.searchValue),
        setIn(`${action.formKey}.data.${action.fieldKey}.actualValue`, action.value.actualValue)
      )(state);

    default:
      return state;
  }
};

export default form;
export { initialState };
