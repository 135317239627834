//

import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import { SpringLogo } from 'components/atoms/Assets/svgs';

import styles from './styles.scss';
import { Box, Flex } from '@springcare/sh-component-library';

const LoadingPage = (props) => (
  <div
    className={classnames(styles.loading, {
      [styles.out]: props.out,
      [styles.hidden]: props.hidden,
    })}
  >
    <Box position="absolute" maxW="360px" right={0} left={0} mx="auto">
      <Flex h="100vh">
        <SpringLogo />
      </Flex>
    </Box>
  </div>
);

LoadingPage.propTypes = {
  hidden: PropTypes.any,
  out: PropTypes.any,
};

export default LoadingPage;
