import { gql } from '@apollo/client';

const trialDetails = gql`
  fragment trialDetails on Trial {
    id
    child_dependents_allowed
    coaching_supported
    company_name
    create_passwords
    dependents_allowed
    end_date
    in_person_supported
    moments_supported
    start_date
    status
    created_at
    updated_at
    customer_id
  }
`;

export default trialDetails;
