const API_PATH = '/api';
const GQL_PATH = '/graphql';

const getConfig = (key, defaultValue) => {
  if (typeof window !== 'undefined') {
    return localStorage.getItem(key) || defaultValue;
  }
  return defaultValue;
};

const rotom = getConfig('rotom', process.env.APP_API);
const diglet = getConfig('diglet', process.env.AUTH_API_DOMAIN);
const ehr = getConfig('ehr', process.env.EHR_URL);
const atlas = getConfig('atlas', process.env.ATLAS_API_DOMAIN || process.env.APP_API);

export const DEFAULT_API = `${rotom}${API_PATH}${GQL_PATH}`;
export const API_BASE = `${rotom}${API_PATH}`;
export const API_GRAPHQL = '/graphql';
export const API_REST = '';

export const API_DOMAIN = rotom;
export const AUTH_API_DOMAIN = diglet;
export const EHR_URL = ehr;
export const ATLAS_API_DOMAIN = atlas;

// Debug constants
export const PIVOTAL_BASE = 'https://www.pivotaltracker.com/services/v5/projects';
