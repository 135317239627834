//
import { ProviderRole } from '@spring/constants';

const removeSpecialTags = (tags, omittedKinds = ['GENDER', 'ETHNICITY', 'LANGUAGE']) => {
  return tags.filter((tag) => !omittedKinds.includes(tag.kind));
};

const shouldShowTags = (providerData) =>
  !(providerData?.roles?.includes('Care Navigator') || providerData?.careTeamRole === ProviderRole.CareNavigator);

export { removeSpecialTags, shouldShowTags };
