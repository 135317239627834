/**
 * Enums for Care Provider Tag Kinds.
 */

const CareProviderTagKind = Object.freeze({
  Condition: 'CONDITION',
  Specialty: 'SPECIALTY',
  TreatmentModality: 'TREATMENT_MODALITY',
  Language: 'LANGUAGE',
  Gender: 'GENDER',
  Ethnicity: 'ETHNICITY',
  Description: 'DESCRIPTION_TAG',
});

export default CareProviderTagKind;
