/**
 * Enums for passing in Appointment Kinds
 * where necessary.
 */

export const AppointmentKind = Object.freeze({
  Therapy: 'THERAPY',
  CouplesTherapy: 'COUPLES_THERAPY',
  MinorTherapy: 'MINOR_THERAPY',
  InitialCareNavigation: 'INITIAL_CARE_NAVIGATION',
  FollowUpCareNavigation: 'FOLLOW_UP_CARE_NAVIGATION',
  InitialMedicationManagement: 'INITIAL_MEDICATION_MANAGEMENT',
  FollowUpMedicationManagement: 'FOLLOW_UP_MEDICATION_MANAGEMENT',
  Coaching: 'COACHING',
});

/**
 * Enums for passing in Requestable Appointment Kinds
 * where necessary.
 */

export const RequestableAppointmentKind = Object.freeze({
  Therapy: 'THERAPY',
  CouplesTherapy: 'COUPLES_THERAPY',
  MinorTherapy: 'MINOR_THERAPY',
  CareNavigation: 'CARE_NAVIGATION',
  MedicationManagement: 'MEDICATION_MANAGEMENT',
  Coaching: 'COACHING',
  PeerRecovery: 'PEER_RECOVERY',
  SpecialtyCareNavigation: 'SPECIALTY_CARE_NAVIGATION',
});
