import React, { useEffect } from 'react';
import Image from 'next/image';
import { Box, Flex, Heading, SimpleGrid, Text, VStack, useMediaQuery } from '@springcare/sh-component-library';
import { SHHeaderMaintenance } from './SHHeaderMaintenance';
import { TermsAndPolicyFooter } from './TermsAndPolicyFooter';
import Auth from 'services/auth';

const Maintenance = () => {
  const [isMobileViewport] = useMediaQuery('(max-width: 767px)');

  useEffect(() => {
    Auth.logout();
  }, []);

  return (
    <>
      <Box as="main" p={0} maxW="100vw" minH="100vh">
        <SHHeaderMaintenance isMobileViewport={isMobileViewport} />

        <SimpleGrid
          columns={[1, 1, 2, 2]}
          minH={['none', 'none', 'calc(100vh - 95px)']}
          pb={isMobileViewport ? '80px' : 0}
        >
          {/* Left pane */}
          <VStack
            align="center"
            justify="center"
            direction="column"
            bg={['background-base', 'background-base', 'accent-on-base']}
            pb="95px"
          >
            <Box w={['80%', '80%', '55%']}>
              <Flex align="center" justify="center" direction="column" tabIndex={0}>
                <Image priority src="/static/mindfulness.svg" alt="Mindfulness" width="360" height="360" />
              </Flex>
            </Box>
          </VStack>

          {/* Right pane */}
          <VStack align="center" justify="center" direction="column" bg={'background-base'} pb="95px">
            <Box w={['80%', '80%', '55%']}>
              <Box mb={'48px'}>
                <Heading color="content-primary" size="heading-medium" mb={'16px'}>
                  Give us a moment. We're improving Spring Health.
                </Heading>
                <Text color="content-primary" size="body-medium-regular">
                  Spring Health is currently unavailable due to scheduled maintenance to improve your experience. We
                  expect our site to be back at approximately 5 am EST. Thank you for your patience.
                </Text>
              </Box>
            </Box>
          </VStack>
        </SimpleGrid>
        <TermsAndPolicyFooter isMobileViewport={isMobileViewport} />
      </Box>
    </>
  );
};

export { Maintenance };
