//

import flow from 'lodash/fp/flow';
import without from 'lodash/without';
import { setIn } from 'utils/immutability';

import {
  OPEN_MODAL,
  CLOSE_MODAL,
  ADD_OVERLAY,
  REMOVE_OVERLAY_BY_ID,
  REMOVE_FIRST_OVERLAY,
} from '../../actions/modal/types';

export const initialState = {
  overlays: [],
};

const modal = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_MODAL:
      return flow(setIn(`${action.id}.open`, true), setIn(`${action.id}.data`, action.data))(state);

    case CLOSE_MODAL:
      return setIn(`${action.id}.open`, false, state);

    case ADD_OVERLAY:
      return setIn('overlays', [...state.overlays, action.id], state);

    case REMOVE_OVERLAY_BY_ID:
      return setIn('overlays', without(state.overlays, action.id), state);

    case REMOVE_FIRST_OVERLAY:
      return setIn('overlays', [...state.overlays].slice(1), state);

    default:
      return state;
  }
};

export default modal;
