import clone from 'lodash/fp/clone';
import setWith from 'lodash/fp/setWith';
import curry from 'lodash/fp/curry';

/**
 * Takes a path, a value, and the object to set into.
 * Sets the value while keeping the original object intact.
 *
 * @name setIn
 * @param {(string | Array<String | Number>)} path
 * @param {*} value
 * @param {Object} obj
 * @returns {Object} Returns a new object with updated value.
 *
 * @example
 * const x = {a: { b: 'overwrite me!'}};
 * const y = setIn('a.b', 'c', x);
 *
 * console.log(x.a.b, y.a.b) // 'overwrite me!', 'c'
 */

function curried(path, value, obj) {
  return setWith(clone, path, value, clone(obj));
}

const setIn = curry(curried);

export default setIn;
