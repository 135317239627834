import { useToast as useSHToast } from '@springcare/sh-component-library';

import type { UseToastOptions } from '@springcare/sh-component-library';

// A hook that wraps the toast/notification functionality.
export const useNotification = (hookOptions?: UseToastOptions) => {
  const toast = useSHToast();

  const notify = (toastOptions?: UseToastOptions) => {
    const isErrorStatus = toastOptions?.status === 'error' || hookOptions?.status === 'error';

    const options = {
      duration: isErrorStatus ? null : 3000,
      isClosable: true,
      ...hookOptions,
      ...toastOptions,
    };

    toast({ ...options });
  };

  return notify;
};
