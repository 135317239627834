import { gql } from '@apollo/client';

import paging from '../../fragments/paging';

const getTrials = gql`
  query getTrials($limit: Int = 30, $offset: Int = 0) {
    trials(limit: $limit, offset: $offset) {
      data {
        id
        child_dependents_allowed
        coaching_supported
        company_name
        create_passwords
        dependents_allowed
        end_date
        in_person_supported
        moments_supported
        start_date
        status
        created_at
        updated_at
        customer_id
        user_count
      }
      paging {
        ...paging
      }
    }
  }
  ${paging}
`;

export default getTrials;
