import { ApolloClient, InMemoryCache, ApolloLink, from, HttpLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import fetch from 'isomorphic-fetch';

import { fieldPolicies } from './typePolicies/fieldPolicies';
import QueryTypePolicies from './typePolicies/query';
import { API_BASE, API_GRAPHQL, ATLAS_API_DOMAIN } from 'constants/api';
import { ddGlobalSessionId } from 'utils/telemetry';

import type { NormalizedCacheObject } from '@apollo/client';

interface GlobalFetch extends NodeJS.Global {
  fetch: any;
}

const isBrowserEnv = typeof window !== 'undefined';

// Polyfill fetch() on the server (used by apollo-client)
if (!isBrowserEnv) {
  (global as GlobalFetch).fetch = fetch;
}

let apolloClient: null | ApolloClient<NormalizedCacheObject> = null;

const authMiddleware = new ApolloLink((operation, forward) => {
  if (localStorage && typeof window !== 'undefined') {
    const access_token = localStorage.getItem('access_token');
    const token_type = localStorage.getItem('token_type');

    operation.setContext({
      headers: {
        Authorization: `${token_type} ${access_token}`,
        'x-springhealth-client-path': window.location.pathname,
        'x-springhealth-magic-id': ddGlobalSessionId.getSessionId(),
      },
    });
  }

  return forward(operation);
});

/**
 * FIXME: See https://springhealth.atlassian.net/browse/PFRM-851
 */
const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  if (!graphQLErrors && !networkError) {
    return forward(operation);
  }
});

const clientLinkFetchOptions = {
  credentials: 'same-origin',
};

const rotomClientLink = new HttpLink({
  uri: `${API_BASE}${API_GRAPHQL}`,
  fetchOptions: {
    ...clientLinkFetchOptions,
  },
});

const atlasClientLink = new HttpLink({
  uri: `${ATLAS_API_DOMAIN}${API_GRAPHQL}`,
  fetchOptions: {
    ...clientLinkFetchOptions,
  },
});

const http = () =>
  // FYI, with the split method, if the boolean resolves to true, the first link is used, otherwise the second
  ApolloLink.split((operation) => operation.getContext().clientName === 'atlas', atlasClientLink, rotomClientLink);

function create() {
  return new ApolloClient({
    /**
     * NOTE - Do not set connectToDevTools to false.
     *
     * See https://springhealth.atlassian.net/wiki/x/uADdX
     * See https://springhealth.atlassian.net/browse/CORE-2126
     */
    connectToDevTools: true,
    ssrMode: !isBrowserEnv, // Disables forceFetch on the server (so queries are only run once)
    link: from([authMiddleware, errorLink, http()]),
    cache: new InMemoryCache({
      typePolicies: {
        ...QueryTypePolicies,
        ...fieldPolicies,
      },
    }),
  });
}

export default function initApollo() {
  // Make sure to create a new client for every server-side request so that data
  // isn't shared between connections (which would be bad)
  if (!isBrowserEnv) {
    return create();
  }

  // Reuse client on the client-side
  if (!apolloClient) {
    apolloClient = create();
  }

  return apolloClient;
}
