class EnvironmentUtils {
  constructor(processEnv = {}) {
    this.appEnv = processEnv.appEnv;
    this.careTeamEmailEnv = processEnv.careTeamEmail;
    this.retentlyEnvTag = processEnv.retentlyEnvTag;
  }

  get retentlyEnabled() {
    return !!this.retentlyEnvTag;
  }

  get careTeamEmail() {
    return this.careTeamEmailEnv || 'careteam@springhealth.com';
  }

  get magicEnvToggleEnabled() {
    return this.appEnv ? !this.isProductionEnv : false;
  }

  get redirectCherrimHomepageToSignIn() {
    return this.isInternalOrDemoEnv;
  }

  get redirectShayminHomepageToSignIn() {
    return this.isProductionEnv;
  }
  get isInternalEnv() {
    return this.appEnv === 'internal' || this.appEnv === 'internalStaging';
  }

  get isInternalOrDemoEnv() {
    return ['internal', 'demo'].includes(this.appEnv);
  }

  get isProductionEnv() {
    return ['production', 'internal', 'demo'].includes(this.appEnv);
  }
}

export default EnvironmentUtils;
