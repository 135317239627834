import get from 'lodash/fp/get';

export function launchdarklyIdentify(userIdentifiers, ldClient) {
  if (userIdentifiers?.user?.id && userIdentifiers?.user?.email) {
    const user = {
      key: get('user.id', userIdentifiers),
      email: get('user.email', userIdentifiers),
    };

    ldClient.identify(user);
  }
}
