import { useLazyQuery } from '@apollo/client';
import { useEffect } from 'react';

export const useQueryWithTable = (query, queryOptions) => {
  const [fetchResults, results] = useLazyQuery(query, queryOptions);

  useEffect(() => {
    fetchResults();
  }, []);

  return { fetchResults, results };
};
