import { Box, Flex, Link, Text } from '@springcare/sh-component-library';

interface ITermsAndPolicyProps {
  isMobileViewport?: boolean;
}

export const TermsAndPolicyFooter = ({ isMobileViewport }: ITermsAndPolicyProps) => (
  <Box
    as="footer"
    position="absolute"
    bottom={0}
    zIndex={998}
    bg={isMobileViewport ? 'background-base' : 'accent-on-base'}
    w={['100%', '100%', '50%']}
    mb="32px"
  >
    <Flex flexDirection={['column', 'column', 'column', 'row-reverse']}>
      <Box w={[null, null, null, '50%']}>
        <Flex flexDirection="row" justifyContent={['space-around']}>
          <Link target="_blank" rel="noopener noreferrer" href="https://springhealth.com/privacy-policy/">
            <Text color="primary-base" size="body-medium-strong">
              Privacy Policy
            </Text>
          </Link>
          <Link target="_blank" rel="noopener noreferrer" href="https://springhealth.com/terms-of-service/">
            <Text color="primary-base" size="body-medium-strong">
              Terms of Service
            </Text>
          </Link>
        </Flex>
      </Box>
      <Box w={[null, null, null, '50%']} mt={['16px', '16px', '16px', 0]}>
        <Flex justifyContent={['center', 'center']}>
          <Text color="content-primary" size="body-medium">
            © 2023 Spring Care, Inc. All rights reserved.
          </Text>
        </Flex>
      </Box>
    </Flex>
  </Box>
);
