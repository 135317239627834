import { gql } from '@apollo/client';

import paging from '../../fragments/paging';

const getChannelPartners = gql`
  query getChannelPartners($launched: Boolean, $customer_or_employer_or_peo: String) {
    channel_partners(launched: $launched, customer_or_employer_or_peo: $customer_or_employer_or_peo) {
      data {
        id
        launched
        name
        active_at
        contract_end_at
        channel_partner_type
        channel_partner_sub_type
        landing_page_url
      }
      paging {
        ...paging
      }
    }
  }
  ${paging}
`;

export default getChannelPartners;
