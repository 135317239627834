import { gql } from '@apollo/client';

const paging = gql`
  fragment paging on PagingData {
    page
    pages
    total
    limit
  }
`;

export default paging;
