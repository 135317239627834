import React from 'react';
import App, { AppProps } from 'next/app';

import Head from 'next/head';
import { Provider } from 'react-redux';
import { ApolloProvider } from '@apollo/client';
import { withLDProvider } from 'launchdarkly-react-client-sdk';

import Initialize from 'components/molecules/Initialize';
import withReduxStore from 'utils/redux/wrapper';
import initApollo from 'utils/apollo';
import ThemeProvider from 'design-system/theme';
import { initializeAndStartDD } from '../utils/datadog/datadog';

import { ErrorBoundary } from 'modules/shared/components/ErrorBoundary';

import '../styles/root.scss';
import { AuthContextProvider } from 'modules/shared/context/AuthContextProvider';

import type { ReactElement, ReactNode } from 'react';
import type { NextPage } from 'next';
import EnvDetails from '../modules/shared/components/EnvDetails';

export type NextPageWithLayout<P = object, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
  reduxStore?: any;
};

const apolloClient = initApollo();

class Spring extends App {
  componentDidMount() {
    localStorage.setItem('initialTarget', window.location.pathname);

    initializeAndStartDD(this.props.router);

    window.onbeforeunload = () => {
      localStorage.removeItem('initialTarget');
    };
  }

  render() {
    const { Component, pageProps, reduxStore, router }: AppPropsWithLayout = this.props;

    // Use the layout defined at the page level, if available
    const getLayout = Component.getLayout ?? ((page) => page);
    return (
      <>
        <Head>
          <title>Spring Health</title>
          <meta content="width=device-width,initial-scale=1" name="viewport" />
        </Head>
        <ApolloProvider client={apolloClient}>
          <Provider store={reduxStore}>
            <ErrorBoundary>
              <AuthContextProvider>
                <ThemeProvider>
                  <EnvDetails />
                  {/* Wrap ErrorBoundary in Providers, mainly to grant access to ReduxStore due to PageBase */}
                  <Initialize>
                    {getLayout(<Component url={{ query: router.query, pathname: router.pathname }} {...pageProps} />)}
                  </Initialize>
                </ThemeProvider>
              </AuthContextProvider>
            </ErrorBoundary>
          </Provider>
        </ApolloProvider>
      </>
    );
  }
}

export default withLDProvider({
  clientSideID: `${process.env.LAUNCH_DARKLY_CLIENT_ID}`,
  options: {},
  reactOptions: {
    useCamelCaseFlagKeys: false,
  },
})(withReduxStore(Spring));
