const cancelAppointmentModal = 'Cancel Appointment Modal';
const exitModal = 'Exit Modal';
const assignCareProviderModal = 'Assign Care Provider Modal';
const destroyResourceModal = 'Destroy Resource Modal';
const confirmationModal = 'Confirmation Modal';
const memberTerminationModal = 'Member Termination Modal';
const planConfirmDeleteModal = 'Plan Confirm Delete Modal';
const planDiscardConfirmationModal = 'Plan Discard Confirmation Modal';
const medManagementExcessWarningModal = 'Med Management Excess Warning Modal';
const careProviderDetailModal = 'Care Provider Detail Modal';
const careProviderScheduleModal = 'Care Provider Schedule Modal';
const backupCodesModal = 'Backup Codes Modal';
const enterMFACodeModal = 'Enter MFA Code Modal';

export default {
  cancelAppointmentModal,
  exitModal,
  assignCareProviderModal,
  destroyResourceModal,
  confirmationModal,
  memberTerminationModal,
  planConfirmDeleteModal,
  planDiscardConfirmationModal,
  medManagementExcessWarningModal,
  careProviderDetailModal,
  careProviderScheduleModal,
  backupCodesModal,
  enterMFACodeModal,
};
