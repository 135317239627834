import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

import Auth from 'services/auth';
import { AuthContext } from './AuthContext';
import LoadingPage from '../../../components/layout/Page/LoadingPage';
import { useNotification } from '../hooks';
import { useApolloClient } from '@apollo/client';
import { PRIVATE_PATHS } from 'modules/shared/constants/auth';

export const AuthContextProvider = ({ children }) => {
  const router = useRouter();
  const notify = useNotification();

  const [userLoading, setUserLoading] = useState(true);
  const [user, setUser] = useState(undefined);
  const apollo = useApolloClient();

  const getIdentifiers = async () => {
    setUserLoading(true);
    try {
      const me = await Auth.getMe();

      setUser(me?.data);

      setUserLoading(false);
    } catch (e) {
      notify({
        status: 'error',
        description: 'Could not fetch user data. Please try again.',
      });
      setUserLoading(false);
      return router.replace('/logout');
    }
  };

  const isOnPrivatePath = PRIVATE_PATHS.some((path) => router.asPath.toLowerCase().includes(path));

  useEffect(() => {
    if (isOnPrivatePath) {
      getIdentifiers();
    } else {
      setUserLoading(false);
    }
  }, []);

  const onSignInInitializeUser = (user) => {
    return setUser(user);
  };

  const onLogout = () => {
    apollo.clearStore();
    setUser(undefined);
  };

  if (userLoading) {
    return <LoadingPage />;
  }

  return (
    <>
      <AuthContext.Provider value={{ user, loading: userLoading, onSignInInitializeUser, onLogout }}>
        {children}
      </AuthContext.Provider>
    </>
  );
};
