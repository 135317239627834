import React, { useContext, useEffect } from 'react';
import { useLDClient } from 'launchdarkly-react-client-sdk';

import { integrationsIdentify, useIdentitySettersForIntegrations } from 'utils/integrations/integrations';
import { trackAppEvent, useAndInitializeUserTracking } from 'utils/userTracking';

import { useRouter } from 'next/router';
import { AuthContext } from 'modules/shared/context/AuthContext';
import { FLAGS, useFeatureFlag } from 'utils/launchdarkly';
import { Maintenance } from 'modules/shared/components/Maintenance';

interface IProps {
  children: React.ReactNode;
}

const Initialize = (props: IProps) => {
  const { user } = useContext(AuthContext);
  const router = useRouter();

  const ldClient = useLDClient();

  useAndInitializeUserTracking();
  useIdentitySettersForIntegrations(user);

  useEffect(() => {
    if (!ldClient) return;

    integrationsIdentify(
      { user },
      {
        ldClient,
      }
    );
  }, [user, ldClient]);

  const handleRouteChange = (newRoute) => {
    trackAppEvent.pageViewed(newRoute);
  };

  const showMaintenancePage = useFeatureFlag(FLAGS.MAINTENANCE_MODE);

  useEffect(() => {
    //Track landing page
    handleRouteChange(router.asPath);

    // When the component is mounted, subscribe to router changes
    // and log those page views
    // router passes the new asPath to the event handler
    router.events.on('routeChangeComplete', handleRouteChange);

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, []);

  return <>{ldClient && showMaintenancePage ? <Maintenance /> : <>{props.children}</>}</>;
};

export { Initialize };
export default Initialize;
