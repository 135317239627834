import { gql } from '@apollo/client';

const getAppointmentKinds = gql`
  query appointment_kinds($provider: ID!, $member: ID!, $provider_role: CareProviderRoleForMembers!) {
    appointment_kinds(provider: $provider, member: $member, provider_role: $provider_role) {
      appointment_kind
      session_type
    }
  }
`;

export default getAppointmentKinds;
