import { useQuery } from '@apollo/client';
import { getAppointmentKinds } from 'operations/queries/appointment_kinds';

export enum CareProviderRole {
  CareNavigator = 'care_navigator',
  Therapist = 'therapist',
  MedicationManager = 'medication_manager',
  Coach = 'coach',
}

type AppointmentKindsResult = {
  appointment_kinds: {
    appointment_kind: string;
    session_type: string;
  };
};

const useAppointmentKinds = ({
  memberId,
  providerId,
  provider_role,
  disabled,
}: {
  memberId?: string;
  providerId: string;
  provider_role: CareProviderRole;
  disabled: boolean;
}) => {
  const isInvalid = !memberId || typeof providerId !== 'string' || typeof provider_role !== 'string';

  return useQuery<AppointmentKindsResult>(getAppointmentKinds, {
    variables: { member: memberId, provider: providerId, provider_role },
    onError: (error) => console.log(error),
    skip: disabled || isInvalid,
  });
};

export default useAppointmentKinds;
