/**
 * Takes an ISO2 or ISO3 country,
 * and returns true if the US or Canada is included in it.
 *
 * @param {string} country ISO2 or ISO3 country
 * @returns {boolean}
 */
export const isUnitedStatesOrCanada = (country) => {
  if (!country) {
    return false;
  }
  const options = ['US', 'USA', 'UNITED STATES', 'CA', 'CAN', 'CANADA'];
  return options.includes(country.toUpperCase());
};

/**
 *
 * @param {string} countryCode 2 letter ISO2 country code eg, US, CA,
 * @returns {boolean}
 */
export const isCountryWhereWeConsiderStateLicensure = (countryCode) => isUnitedStatesOrCanada(countryCode);

export default {
  isCountryWhereWeConsiderStateLicensure,
  isUnitedStatesOrCanada,
};
