import React, { useState } from 'react';

import {
  AddIcon,
  Flex,
  IconButton,
  MinusIcon,
  Text,
  useDisclosure,
  useMediaQuery,
} from '@springcare/sh-component-library';
import { desktopMediaQueryWidth } from 'modules/shared/components/Navigation/constants/styles';
import { MagicEnvModal } from '../MagicEnvModal';
import { useRouter } from 'next/router';

// Display localStorage items from Magic Env Toggle first, then default to .env values if provided
const EnvDetails = () => {
  const envModal = useDisclosure();
  const router = useRouter();
  const [isExpanded, setIsExpanded] = useState(true);
  const [isDesktop] = useMediaQuery(desktopMediaQueryWidth);
  const [environment, apiUrl, authApiUrl, ehrApiUrl, atlasApiUrl] = [
    process.env.APP_ENV,
    process.env.APP_API,
    process.env.AUTH_API_DOMAIN,
    process.env.EHR_URL,
    process.env.ATLAS_API_DOMAIN,
  ];

  if (environment !== 'dev' && environment !== 'staging') {
    return null;
  }

  const rotom = localStorage.getItem('rotom');
  const diglet = localStorage.getItem('diglet');
  const ehr = localStorage.getItem('ehr');
  const atlas = localStorage.getItem('atlas');

  const envs = [
    { name: 'Rotom', value: rotom || apiUrl },
    { name: 'Auth', value: diglet || authApiUrl },
    { name: 'EHR', value: ehr || ehrApiUrl },
    { name: 'Atlas', value: atlas || atlasApiUrl },
  ];

  return (
    <Flex
      data-testid="env-details"
      role="complementary"
      width="100%"
      justifyContent="center"
      position="fixed"
      zIndex={1000}
    >
      <Flex
        backgroundColor="negative-base"
        display={isDesktop ? 'flex' : 'none'}
        _hover={{ cursor: 'pointer', boxShadow: 'md' }}
        gap={4}
        py={1}
        pl={4}
        borderBottomRadius={4}
      >
        <Flex
          gap={4}
          // onClick={() => envModal.onOpen()}
          onClick={() => router.push('/')}
        >
          {isExpanded ? (
            envs.map((env) => (
              <Text key={env.name} fontSize="sm" color="white" display="flex" gap={1}>
                <b>{env.name}:</b>
                {` ${env.value}`}
              </Text>
            ))
          ) : (
            <Text fontSize="sm" color="white" fontWeight="bold">
              ENV Details
            </Text>
          )}
        </Flex>
        <IconButton
          variant="no-emphasis"
          size="xs"
          icon={isExpanded ? MinusIcon : AddIcon}
          aria-label="collapse"
          onClick={() => setIsExpanded(!isExpanded)}
          height="unset"
          minHeight="unset"
          colorScheme="neutral"
          color="white"
        />
      </Flex>

      <MagicEnvModal {...envModal} />
    </Flex>
  );
};

export default EnvDetails;
