//

import PropTypes from 'prop-types';
import React from 'react';

const CheckBoldSvg = ({ height = '24', width = '24' }) => (
  <svg width={width} height={height} viewBox="0 0 24 24">
    <path fill="#1C6B57" d="M9,20.42L2.79,14.21L5.62,11.38L9,14.77L18.88,4.88L21.71,7.71L9,20.42Z" />
  </svg>
);

CheckBoldSvg.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
};

export default CheckBoldSvg;
