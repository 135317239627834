import { useClipboard, useToast } from '@springcare/sh-component-library';

export const useCopyToClipboard = ({ value, showToast = true, toastMessage = '' }) => {
  const { onCopy, ...restUseClipboard } = useClipboard(value);
  const toast = useToast();

  const handleCopy = () => {
    onCopy();
    if (showToast) {
      toast({
        description: toastMessage || `"${value}" copied to clipboard`,
        status: 'info',
        duration: 3500,
        isClosable: true,
      });
    }
  };

  return {
    handleCopy,
    ...restUseClipboard,
  };
};
