/**
 * Enums for Tag colors.
 */

export const TagColors = Object.freeze({
  Pear: 'pear',
  Primary: 'primary',
  Accent: 'accent',
  Secondary: 'secondary',
  Tertiary: 'tertiary',
  Alternate: 'alternate',
  Gray: 'gray',
});
