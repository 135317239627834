import { useEffect } from 'react';

import { datadogIdentify } from '../datadog/datadog';
import { launchdarklyIdentify } from 'utils/launchdarkly/client';
import { setUserTrackingIdentity } from 'utils/userTracking';

import type { LDClient } from 'launchdarkly-js-client-sdk';

/**
 * TODO: Move these identifier functions into the below hook. This will also help us abstract the
 * call to useLDClient on line #34 of Initialize.tsx.
 *
 * https://springhealth.atlassian.net/browse/CORE-1969
 */
export const integrationsIdentify = (userIdentifiers, options: { ldClient: LDClient }) => {
  launchdarklyIdentify(userIdentifiers, options.ldClient);
  datadogIdentify(userIdentifiers);

  return;
};

/**
 * Hook to set user identity into various integrations/dependencies.
 */
export const useIdentitySettersForIntegrations = (user) => {
  useEffect(() => {
    if (user) {
      setUserTrackingIdentity(user);
    }
  }, [user]);
};
