const US_STATES = [
  { label: 'AL', value: 'AL', fullName: 'Alabama' },
  { label: 'AK', value: 'AK', fullName: 'Alaska' },
  { label: 'AZ', value: 'AZ', fullName: 'Arizona' },
  { label: 'AR', value: 'AR', fullName: 'Arkansas' },
  { label: 'CA', value: 'CA', fullName: 'California' },
  { label: 'CO', value: 'CO', fullName: 'Colorado' },
  { label: 'CT', value: 'CT', fullName: 'Connecticut' },
  { label: 'DE', value: 'DE', fullName: 'Delaware' },
  { label: 'DC', value: 'DC', fullName: 'District Of Columbia' },
  { label: 'FL', value: 'FL', fullName: 'Florida' },
  { label: 'GA', value: 'GA', fullName: 'Georgia' },
  { label: 'HI', value: 'HI', fullName: 'Hawaii' },
  { label: 'ID', value: 'ID', fullName: 'Idaho' },
  { label: 'IL', value: 'IL', fullName: 'Illinois' },
  { label: 'IN', value: 'IN', fullName: 'Indiana' },
  { label: 'IA', value: 'IA', fullName: 'Iowa' },
  { label: 'KS', value: 'KS', fullName: 'Kansas' },
  { label: 'KY', value: 'KY', fullName: 'Kentucky' },
  { label: 'LA', value: 'LA', fullName: 'Louisiana' },
  { label: 'ME', value: 'ME', fullName: 'Maine' },
  { label: 'MD', value: 'MD', fullName: 'Maryland' },
  { label: 'MA', value: 'MA', fullName: 'Massachusetts' },
  { label: 'MI', value: 'MI', fullName: 'Michigan' },
  { label: 'MN', value: 'MN', fullName: 'Minnesota' },
  { label: 'MS', value: 'MS', fullName: 'Mississippi' },
  { label: 'MO', value: 'MO', fullName: 'Missouri' },
  { label: 'MT', value: 'MT', fullName: 'Montana' },
  { label: 'NE', value: 'NE', fullName: 'Nebraska' },
  { label: 'NV', value: 'NV', fullName: 'Nevada' },
  { label: 'NH', value: 'NH', fullName: 'New Hampshire' },
  { label: 'NJ', value: 'NJ', fullName: 'New Jersey' },
  { label: 'NM', value: 'NM', fullName: 'New Mexico' },
  { label: 'NY', value: 'NY', fullName: 'New York' },
  { label: 'NC', value: 'NC', fullName: 'North Carolina' },
  { label: 'ND', value: 'ND', fullName: 'North Dakota' },
  { label: 'OH', value: 'OH', fullName: 'Ohio' },
  { label: 'OK', value: 'OK', fullName: 'Oklahoma' },
  { label: 'OR', value: 'OR', fullName: 'Oregon' },
  { label: 'PA', value: 'PA', fullName: 'Pennsylvania' },
  { label: 'PR', value: 'PR', fullName: 'Puerto Rico' },
  { label: 'RI', value: 'RI', fullName: 'Rhode Island' },
  { label: 'SC', value: 'SC', fullName: 'South Carolina' },
  { label: 'SD', value: 'SD', fullName: 'South Dakota' },
  { label: 'TN', value: 'TN', fullName: 'Tennessee' },
  { label: 'TX', value: 'TX', fullName: 'Texas' },
  { label: 'UT', value: 'UT', fullName: 'Utah' },
  { label: 'VT', value: 'VT', fullName: 'Vermont' },
  { label: 'VA', value: 'VA', fullName: 'Virginia' },
  { label: 'WA', value: 'WA', fullName: 'Washington' },
  { label: 'WV', value: 'WV', fullName: 'West Virginia' },
  { label: 'WI', value: 'WI', fullName: 'Wisconsin' },
  { label: 'WY', value: 'WY', fullName: 'Wyoming' },
  { label: '-', value: null },
];

const CANADIAN_PROVINCES = [
  { label: 'AB', value: 'AB', fullName: 'Alberta' },
  { label: 'BC', value: 'BC', fullName: 'British Columbia' },
  { label: 'MB', value: 'MB', fullName: 'Manitoba' },
  { label: 'NB', value: 'NB', fullName: 'New Brunswick' },
  { label: 'NL', value: 'NL', fullName: 'Newfoundland and Labrador' },
  { label: 'NT', value: 'NT', fullName: 'Northwest Territories' },
  { label: 'NS', value: 'NS', fullName: 'Nova Scotia' },
  { label: 'NU', value: 'NU', fullName: 'Nunavut' },
  { label: 'ON', value: 'ON', fullName: 'Ontario' },
  { label: 'PE', value: 'PE', fullName: 'Prince Edward Island' },
  { label: 'QC', value: 'QC', fullName: 'Quebec' },
  { label: 'SK', value: 'SK', fullName: 'Saskatchewan' },
  { label: 'YT', value: 'YT', fullName: 'Yukon' },
  { label: '-', value: null },
];

const AUSTRALIAN_STATES = [
  { label: 'NSW', value: 'NSW', fullName: 'New South Wales' },
  { label: 'QLD', value: 'QLD', fullName: 'Queensland' },
  { label: 'SA', value: 'SA', fullName: 'South Australia' },
  { label: 'TAS', value: 'TAS', fullName: 'Tasmania' },
  { label: 'VIC', value: 'VIC', fullName: 'Victoria' },
  { label: 'WA', value: 'WA', fullName: 'Western Australia' },
  { label: 'ACT', value: 'ACT', fullName: 'Australian Capital Territory' },
  { label: 'NT', value: 'NT', fullName: 'Northern Territory' },
  { label: '-', value: null },
];

const BRAZILIAN_STATES = [
  { label: 'AC', value: 'AC', fullName: 'Acre' },
  { label: 'AL', value: 'AL', fullName: 'Alagoas' },
  { label: 'AP', value: 'AP', fullName: 'Amapá' },
  { label: 'AM', value: 'AM', fullName: 'Amazonas' },
  { label: 'BA', value: 'BA', fullName: 'Bahia' },
  { label: 'CE', value: 'CE', fullName: 'Ceará' },
  { label: 'DF', value: 'DF', fullName: 'Distrito Federal' },
  { label: 'ES', value: 'ES', fullName: 'Espirito Santo' },
  { label: 'GO', value: 'GO', fullName: 'Goiás' },
  { label: 'MA', value: 'MA', fullName: 'Maranhão' },
  { label: 'MT', value: 'MT', fullName: 'Mato Grosso' },
  { label: 'MS', value: 'MS', fullName: 'Mato Grosso do Sul' },
  { label: 'MG', value: 'MG', fullName: 'Minas Gerais' },
  { label: 'PA', value: 'PA', fullName: 'Pará' },
  { label: 'PB', value: 'PB', fullName: 'Paraíba' },
  { label: 'PR', value: 'PR', fullName: 'Paraná' },
  { label: 'PE', value: 'PE', fullName: 'Pernambuco' },
  { label: 'PI', value: 'PI', fullName: 'Piauí' },
  { label: 'RJ', value: 'RJ', fullName: 'Rio de Janeiro' },
  { label: 'RN', value: 'RN', fullName: 'Rio Grande do Norte' },
  { label: 'RS', value: 'RS', fullName: 'Rio Grande do Sul' },
  { label: 'RO', value: 'RO', fullName: 'Rondônia' },
  { label: 'RR', value: 'RR', fullName: 'Roraima' },
  { label: 'SC', value: 'SC', fullName: 'Santa Catarina' },
  { label: 'SP', value: 'SP', fullName: 'São Paulo' },
  { label: 'SE', value: 'SE', fullName: 'Sergipe' },
  { label: 'TO', value: 'TO', fullName: 'Tocantins' },
  { label: '-', value: null },
];

const INDIAN_STATES = [
  { label: 'AP', value: 'AP', fullName: 'Andhra Pradesh' },
  { label: 'AR', value: 'AR', fullName: 'Arunachal Pradesh' },
  { label: 'AS', value: 'AS', fullName: 'Assam' },
  { label: 'BR', value: 'BR', fullName: 'Bihar' },
  { label: 'CG', value: 'CG', fullName: 'Chhattisgarh' },
  { label: 'GA', value: 'GA', fullName: 'Goa' },
  { label: 'GJ', value: 'GJ', fullName: 'Gujarat' },
  { label: 'HR', value: 'HR', fullName: 'Haryana' },
  { label: 'HP', value: 'HP', fullName: 'Himachal Pradesh' },
  { label: 'JH', value: 'JH', fullName: 'Jharkhand' },
  { label: 'KA', value: 'KA', fullName: 'Karnataka' },
  { label: 'KL', value: 'KL', fullName: 'Kerala' },
  { label: 'MP', value: 'MP', fullName: 'Madhya Pradesh' },
  { label: 'MH', value: 'MH', fullName: 'Maharashtra' },
  { label: 'MN', value: 'MN', fullName: 'Manipur' },
  { label: 'ML', value: 'ML', fullName: 'Meghalaya' },
  { label: 'MZ', value: 'MZ', fullName: 'Mizoram' },
  { label: 'NL', value: 'NL', fullName: 'Nagaland' },
  { label: 'OD', value: 'OD', fullName: 'Odisha' },
  { label: 'PB', value: 'PB', fullName: 'Punjab' },
  { label: 'RJ', value: 'RJ', fullName: 'Rajasthan' },
  { label: 'SK', value: 'SK', fullName: 'Sikkim' },
  { label: 'TN', value: 'TN', fullName: 'Tamil Nadu' },
  { label: 'TS', value: 'TS', fullName: 'Telangana' },
  { label: 'TR', value: 'TR', fullName: 'Tripura' },
  { label: 'UP', value: 'UP', fullName: 'Uttar Pradesh' },
  { label: 'UK', value: 'UK', fullName: 'Uttarakhand' },
  { label: 'WB', value: 'WB', fullName: 'West Bengal' },
  { label: 'AN', value: 'AN', fullName: 'Andaman and Nicobar Islands' },
  { label: 'CH', value: 'CH', fullName: 'Chandigarh' },
  { label: 'DD', value: 'DD', fullName: 'Dadra and Nagar Haveli and Daman and Diu' },
  { label: 'DL', value: 'DL', fullName: 'Delhi' },
  { label: 'JK', value: 'JK', fullName: 'Jammu and Kashmir' },
  { label: 'LA', value: 'LA', fullName: 'Ladakh' },
  { label: 'LD', value: 'LD', fullName: 'Lakshadweep' },
  { label: 'PY', value: 'PY', fullName: 'Puducherry' },
  { label: '-', value: null },
];

const IRISH_COUNTIES = [
  { label: 'Galway', value: 'Galway' },
  { label: 'Leitrim', value: 'Leitrim' },
  { label: 'Mayo', value: 'Mayo' },
  { label: 'Roscommon', value: 'Roscommon' },
  { label: 'Sligo', value: 'Sligo' },
  { label: 'Antrim', value: 'Antrim' },
  { label: 'Armagh', value: 'Armagh' },
  { label: 'Cavan', value: 'Cavan' },
  { label: 'Donegal', value: 'Donegal' },
  { label: 'Down', value: 'Down' },
  { label: 'Fermanagh', value: 'Fermanagh' },
  { label: 'Londonderry', value: 'Londonderry' },
  { label: 'Monaghan', value: 'Monaghan' },
  { label: 'Tyrone', value: 'Tyrone' },
  { label: 'Clare', value: 'Clare' },
  { label: 'Cork', value: 'Cork' },
  { label: 'Kerry', value: 'Kerry' },
  { label: 'Limerick', value: 'Limerick' },
  { label: 'Tipperary', value: 'Tipperary' },
  { label: 'Waterford', value: 'Waterford' },
  { label: 'Carlow', value: 'Carlow' },
  { label: 'Dublin', value: 'Dublin' },
  { label: 'Kildare', value: 'Kildare' },
  { label: 'Kilkenny', value: 'Kilkenny' },
  { label: 'Laois', value: 'Laois' },
  { label: 'Longford', value: 'Longford' },
  { label: 'Louth', value: 'Louth' },
  { label: 'Meath', value: 'Meath' },
  { label: 'Offaly', value: 'Offaly' },
  { label: 'Westmeath', value: 'Westmeath' },
  { label: 'Wexford', value: 'Wexford' },
  { label: 'Wicklow', value: 'Wicklow' },
  { label: '-', value: null },
];

const ARGENTINIAN_PROVINCES = [
  { label: 'Buenos Aires', value: 'Buenos Aires' },
  { label: 'Catamarca Province', value: 'Catamarca Province' },
  { label: 'Chaco', value: 'Chaco' },
  { label: 'Chubut', value: 'Chubut' },
  { label: 'C\u00f3rdoba', value: 'Córdoba' },
  { label: 'Corrientes', value: 'Corrientes' },
  { label: 'Entre R\u00edos', value: 'Entre Ríos' },
  { label: 'Formosa', value: 'Formosa' },
  { label: 'Jujuy', value: 'Jujuy' },
  { label: 'La Pampa', value: 'La Pampa' },
  { label: 'La Rioja', value: 'La Rioja' },
  { label: 'Mendoza', value: 'Mendoza' },
  { label: 'Misiones', value: 'Misiones' },
  { label: 'Neuqu\u00e9n', value: 'Neuquén' },
  { label: 'R\u00edo Negro', value: 'Río Negro' },
  { label: 'Salta', value: 'Salta' },
  { label: 'San Juan', value: 'San Juan' },
  { label: 'San Luis', value: 'San Luis' },
  { label: 'Santa Cruz', value: 'Santa Cruz' },
  { label: 'Santa Fe', value: 'Santa Fe' },
  { label: 'Santiago del Estero', value: 'Santiago del Estero' },
  { label: 'Tierra del Fuego', value: 'Tierra del Fuego' },
  { label: 'Tucum\u00e1n', value: 'Tucumán' },
  { label: '-', value: null },
];

const MEXICAN_STATES = [
  { label: 'Aguascalientes', value: 'Aguascalientes' },
  { label: 'Baja California', value: 'Baja California' },
  { label: 'Baja California Sur', value: 'Baja California Sur' },
  { label: 'Campeche', value: 'Campeche' },
  { label: 'Coahuila', value: 'Coahuila' },
  { label: 'Colima', value: 'Colima' },
  { label: 'Chiapas', value: 'Chiapas' },
  { label: 'Chihuahua', value: 'Chihuahua' },
  { label: 'Durango', value: 'Durango' },
  { label: 'Mexico City', value: 'Mexico City' },
  { label: 'Guanajuato', value: 'Guanajuato' },
  { label: 'Guerrero', value: 'Guerrero' },
  { label: 'Hidalgo', value: 'Hidalgo' },
  { label: 'Jalisco', value: 'Jalisco' },
  { label: 'Mexico', value: 'Mexico' },
  { label: 'Michoacan', value: 'Michoacan' },
  { label: 'Morelos', value: 'Morelos' },
  { label: 'Nayarit', value: 'Nayarit' },
  { label: 'Nuevo Le\u00f3n', value: 'Nuevo León' },
  { label: 'Oaxaca', value: 'Oaxaca' },
  { label: 'Puebla', value: 'Puebla' },
  { label: 'Queretaro', value: 'Queretaro' },
  { label: 'Quintana Roo', value: 'Quintana Roo' },
  { label: 'San Luis Potosi', value: 'San Luis Potosi' },
  { label: 'Sinaloa', value: 'Sinaloa' },
  { label: 'Sonora', value: 'Sonora' },
  { label: 'Tabasco', value: 'Tabasco' },
  { label: 'Tamaulipas', value: 'Tamaulipas' },
  { label: 'Tlaxcala', value: 'Tlaxcala' },
  { label: 'Veracruz', value: 'Veracruz' },
  { label: 'Yucat\u00e1n', value: 'Yucatán' },
  { label: 'Zacatecas', value: 'Zacatecas' },
  { label: '-', value: null },
];

const SPANISH_STATES = [
  { label: 'Alava', value: 'Alava' },
  { label: 'Western Andalusia', value: 'Western Andalusia' },
  { label: 'Eastern Andalusia', value: 'Eastern Andalusia' },
  { label: 'Aragon', value: 'Aragon' },
  { label: 'Biscay', value: 'Biscay' },
  { label: 'Cantabria', value: 'Cantabria' },
  { label: 'Castilla la Mancha', value: 'Castilla la Mancha' },
  { label: 'Castile and Leon', value: 'Castile and Leon' },
  { label: 'Catalonia', value: 'Catalonia' },
  { label: 'Ceuta', value: 'Ceuta' },
  { label: 'Valencian Community', value: 'Valencian Community' },
  { label: 'Estremadura', value: 'Estremadura' },
  { label: 'Galicia', value: 'Galicia' },
  { label: 'Gipuzkoa', value: 'Gipuzkoa' },
  { label: 'Balearic Islands', value: 'Balearic Islands' },
  { label: 'Madrid', value: 'Madrid' },
  { label: 'Melilla', value: 'Melilla' },
  { label: 'Navarre', value: 'Navarre' },
  { label: 'The Palms', value: 'The Palms' },
  { label: 'Principality of Asturias', value: 'Principality of Asturias' },
  { label: 'Region of Murcia', value: 'Region of Murcia' },
  { label: 'The Rioja', value: 'The Rioja' },
  { label: 'Santa Cruz of Tenerife', value: 'Santa Cruz of Tenerife' },
  { label: '-', value: null },
];

export default {
  US_STATES,
  CANADIAN_PROVINCES,
  AUSTRALIAN_STATES,
  BRAZILIAN_STATES,
  INDIAN_STATES,
  IRISH_COUNTIES,
  ARGENTINIAN_PROVINCES,
  MEXICAN_STATES,
  SPANISH_STATES,
};
