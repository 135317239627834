//

export const sortTagOptions = (optionsArray, attribute = 'label') => {
  if (!Array.isArray(optionsArray)) return [];

  return optionsArray.sort((opt1, opt2) => {
    if (opt1[attribute] > opt2[attribute]) {
      return 1;
    }
    if (opt1[attribute] < opt2[attribute]) {
      return -1;
    }
    return 0;
  });
};
