import LocalStorageUtils from 'utils/localStorage';
import CookieUtils from 'utils/cookies';
import { AUTH_API_DOMAIN } from 'constants/api';
import { generateRequestHeaders } from './api';

const MFA_DEVICE_ID_SMS = 2;
const MFA_DEVICE_ID_APP = 3;

// REST functions for SSO client portal and API.
// this function returns ALL rows from the DB in pages of 20
// it take the page number and returns the 20 rows for that page
const getSSOClients = async (page: number) => {
  try {
    const response = await fetch(`${AUTH_API_DOMAIN}/v1/sso-clients?page=${page}`, {
      headers: generateRequestHeaders(),
      method: 'GET',
    });

    if (response.status === 200) {
      return response.json();
    }
  } catch (err) {
    throw err;
  }
};

// takes a search param and returns only the rows that have that client name
const getSSOClientBySlug = async (search_slug: string) => {
  try {
    const response = await fetch(`${AUTH_API_DOMAIN}/v1/sso-clients?search_slug=${search_slug}`, {
      headers: generateRequestHeaders(),
    });
    if (response.status === 200) {
      return response.json();
    }
  } catch (err) {
    throw err;
  }
};

// this function returns ONLY THE REQUESTED CLIENT by ID
// requires that a client ID be passed (row ID, primary key)
const getSSOClient = async (sso_client_id: number) => {
  try {
    const response = await fetch(`${AUTH_API_DOMAIN}/v1/sso-clients/${sso_client_id}`, {
      headers: generateRequestHeaders(),
    });

    if (response.status === 200) return response.json();
  } catch (err) {
    throw err;
  }
};

// Regenerate an SSO client SP encryption data
const generateSSOClientSPEncryption = async (sso_client_id) => {
  const response = await fetch(`${AUTH_API_DOMAIN}/v1/sso-clients/${sso_client_id}/generate-sp-encryption`, {
    headers: generateRequestHeaders(),
    method: 'PUT',
  });

  return response.status === 204;
};

// this function makes a post request to DIGLET to create a NEW row in the DB
// only the slug is required, returns errors if data does not pass validation
// data is validated by DIGLET
const createSSOClient = async (sso_client_data) => {
  return fetch(`${AUTH_API_DOMAIN}/v1/sso-clients`, {
    headers: generateRequestHeaders(),
    method: 'POST',
    body: JSON.stringify(sso_client_data),
  }).then((res) => {
    return res.json();
  });
};

// this function makes a PUT request to DIGLET to update the row at the passed ID
// requires the client ID (which should be present and correct if following the proper path by clicking the link generated by the GET request)
// data validation will be handled by DIGLET
const updateSSOClient = (sso_client_data, sso_client_id) => {
  return fetch(AUTH_API_DOMAIN + '/v1/sso-clients/' + sso_client_id, {
    headers: { ...generateRequestHeaders() },
    body: JSON.stringify(sso_client_data),
    method: 'PUT',
  });
};

const _clearSession = () => {
  LocalStorageUtils.clearHeaders();
  CookieUtils.clearHeaders();
};

const _setExpirationTime = (accessExpiresIn: number) => {
  const accessNow = new Date();

  accessNow.setSeconds(accessNow.getSeconds() + accessExpiresIn);
  const accessNowTime = accessNow.getTime();

  LocalStorageUtils.updateTokenExpiration(accessNowTime);
  CookieUtils.updateTokenExpiration(accessNowTime);
};

const refreshAccessToken = async (refreshToken: string) => {
  if (!refreshToken) {
    return false;
  }
  try {
    const response = await createRefreshedAccessToken(refreshToken);
    setRefreshedTokenHeaders(await response.json());
    return true;
  } catch (err) {
    return false;
  }
};

const validateAccess = async (cookie: any) => {
  const { access_token, refresh_token, scopes } = cookie;

  if (shouldLogoutBasedOnScopes(scopes ? JSON.parse(scopes) : [])) return false;

  const parsedToken = parseJwt(access_token);

  const tokenHasId = !(parsedToken === undefined || !parsedToken?.id);
  const tokenIsExpired = !parsedToken?.expires || new Date(parsedToken.expires) <= new Date();

  if (!tokenHasId || tokenIsExpired) {
    return await refreshAccessToken(refresh_token);
  }

  return true;
};

const parseJwt = (access_token: string) => {
  if (!access_token) return undefined;
  try {
    let base64Url = access_token.split('.')[1];
    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    let jsonPayload = Buffer.from(base64, 'base64').toString('utf-8');

    return JSON.parse(jsonPayload);
  } catch (error) {
    console.error('Error parsing JWT: ', error);
    return undefined;
  }
};

const createRefreshedAccessToken = async (token: string) => {
  return fetch(`${AUTH_API_DOMAIN}/oauth/access_token`, {
    headers: generateRequestHeaders(),
    body: JSON.stringify({
      refresh_token: token,
      grant_type: 'refresh_token',
      client_id: process.env.CLIENT_ID,
      client_secret: process.env.CLIENT_SECRET,
    }),
  });
};

const setRefreshedTokenHeaders = (response: any) => {
  const { access_token, refresh_token, token_type, expires_in } = response || {};

  if (access_token && refresh_token && token_type && expires_in) {
    _setExpirationTime(expires_in);
    LocalStorageUtils.updateHeaders({
      access_token,
      refresh_token,
      token_type,
    });
    CookieUtils.updateHeaders({
      access_token,
      refresh_token,
      token_type,
    });
  } else {
    throw new Error('Invalid response from refresh token verification');
  }
};

const shouldLogoutBasedOnScopes = (scopes) => {
  if (!scopes || scopes.length === 0 || typeof scopes === 'string') return true;

  const isAdminUser =
    scopes.includes('group:admin') || scopes.includes('group:super_admin') || scopes.includes('group:sales');
  const isCompassUser = scopes.some((scope) => scope.includes('compass'));

  if (!isAdminUser && isCompassUser) return true;

  return isAdminUser ? false : true;
};

const checkScopes = (username: string, allowedScopes: string[], response) => {
  if (response.error) throw response;

  const { expires_in, scopes } = response;

  const union = (a, b) => Array.from(new Set([...a, ...b]));

  // User doesn't have ANY scopes OR
  // The intersection of the user scopes and the allowed scopes
  // isn't less than the length of both combined.
  //
  // If they have ONE of the scopes, the length of the union
  // would be less than the 2 arrays combined.
  //
  // [1,2] union [2,3] === [1,2,3]
  // It's not the case that [1,2,3].length >= [1,2].length + [2,3].length
  if (allowedScopes) {
    if (!scopes || union(scopes, allowedScopes).length >= scopes.length + allowedScopes.length) {
      throw { error_description: 'Invalid credentials' };
    }
  }

  _setExpirationTime(expires_in);
  LocalStorageUtils.saveHeaders(response, username); //does not save uuid
  CookieUtils.saveHeaders(response, username);
};

const signIn = (username: string, password: string, allowedScopes: any, params = {}) => {
  return fetch(`${AUTH_API_DOMAIN}/oauth/access_token`, {
    headers: generateRequestHeaders(),
    method: 'POST',
    body: JSON.stringify({
      username,
      password,
      // issueToken in Diglet Oauth controller
      grant_type: 'password',
      client_id: process.env.CLIENT_ID,
      client_secret: process.env.CLIENT_SECRET,
      scope: process.env.CLIENT_SCOPE,
      ...params,
    }),
  })
    .then(async (res) => {
      const response = await res.json();
      if (response.error) throw response;
      checkScopes(username, allowedScopes, response);
      return getMe(response.scopes);
    })
    .catch((err) => {
      throw err;
    });
};

const logout = () => {
  const access_token = localStorage.getItem('access_token');

  if (!access_token) {
    _clearSession();
    return;
  }
  //revokeSession in diglet Oauth controller
  return fetch(`${AUTH_API_DOMAIN}/oauth/session`, {
    headers: generateRequestHeaders(),
    method: 'DELETE',
  })
    .then(() => {
      _clearSession();
      return;
    })
    .catch(() => {
      _clearSession();
    });
};

const forgotPassword = (email, redirect) => {
  return fetch(`${AUTH_API_DOMAIN}/v1/users/passwords/forgot?username=${email}&app_url=${redirect}`)
    .then((response) => {
      if (response.status === 204) {
        return 'Reset Password Email Sent';
      }
    })
    .catch((err: Error) => {
      throw err;
    });
};

const resetPassword = (username, password, token) => {
  return fetch(`${AUTH_API_DOMAIN}/v1/users/passwords/reset`, {
    headers: generateRequestHeaders(),
    method: 'POST',
    body: JSON.stringify({
      username,
      password,
      token,
    }),
  })
    .then(({ status }) => {
      if (status === 204) {
        return 'Password has been successfully reset';
      }
    })
    .catch((err: Error) => {
      throw err;
    });
};

const createMFAAccessToken = (mfaToken) => {
  return fetch(`${AUTH_API_DOMAIN}/v1/mfa/challenge`, {
    headers: {
      ...generateRequestHeaders(),
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify({
      mfa_token: mfaToken,
    }),
  })
    .then(async (response) => {
      if (response.status === 200) {
        const res = await response.json();
        return res.data.challenge;
      }
    })
    .catch((err: Error) => {
      throw err;
    });
};

const mfaRegisterDevice = (mfa_type_id, payload = {}) => {
  return fetch(`${AUTH_API_DOMAIN}/v1/me/mfa-devices`, {
    headers: generateRequestHeaders(),
    body: JSON.stringify({ mfa_type_id, payload }),
    method: 'POST',
  })
    .then(async (response) => {
      return response.json();
    })
    .catch((err: Error) => {
      throw err;
    });
};

const mfaVerifyDevice = (mfa_device_id, payload) => {
  return fetch(`${AUTH_API_DOMAIN}/v1/me/mfa-devices/${mfa_device_id}/verify`, {
    headers: generateRequestHeaders(),
    body: JSON.stringify({
      payload,
    }),
    method: 'POST',
  })
    .then((response) => response.json())
    .catch((err) => {
      throw err;
    });
};

const mfaSignIn = (challenge, mfaToken, username, password, allowedScopes) => {
  return fetch(`${AUTH_API_DOMAIN}/oauth/access_token`, {
    headers: generateRequestHeaders(),
    method: 'POST',
    body: JSON.stringify({
      //issueToken in diglet Oauth controller
      username,
      password,
      mfa_token: mfaToken,
      challenge_response: {
        code: challenge,
      },
      grant_type: 'mfa_challenge_response',
      scope: process.env.CLIENT_SCOPE,
      client_id: process.env.CLIENT_ID,
      client_secret: process.env.CLIENT_SECRET,
    }),
  })
    .then(async (res) => {
      const response = await res.json();
      if (response.error) throw response;
      checkScopes(username, allowedScopes, response);
      return getMe(response.scopes); //show function in diglet MeConroller, returns user data
    })
    .catch((err: Error) => {
      throw err;
    });
};

const getMe = async (scopes = []) => {
  try {
    const res = await fetch(`${AUTH_API_DOMAIN}/v1/me`, {
      headers: generateRequestHeaders(),
    });

    const response = await res.json();

    if (res.status === 200) {
      localStorage.setItem('uuid', response.data.id);
      return { ...response, scopes };
    }
  } catch (err) {
    throw err;
  }
};

const getUserInfo = async (user_id: string) => {
  const response = await fetch(AUTH_API_DOMAIN + '/v1/users/' + user_id + '/info', {
    headers: generateRequestHeaders(),
  });
  if (response.status === 200) {
    return response.json();
  }
  // @ts-ignore
  throw Error(response.status);
};

// Get user roles
const getUserRoles = async () => {
  const response = await fetch(AUTH_API_DOMAIN + '/v1/roles', {
    headers: generateRequestHeaders(),
  });
  if (response.status === 200) {
    return response.json();
  }
  // @ts-ignore
  throw Error(response.status);
};

// Updates a user's roles
const updateUserRoles = async (user_id: string, roles: string[]) => {
  const response = await fetch(AUTH_API_DOMAIN + '/v1/users/' + user_id + '/roles', {
    headers: {
      ...generateRequestHeaders(),
      'Content-Type': 'application/json',
    },
    method: 'PUT',
    body: JSON.stringify({ roles: roles }),
  });
  if (response.status === 200) {
    return response.json();
  }
  // @ts-ignore
  throw Error(response.status);
};

// Reactivate a "soft deleted" user
const reactivateUser = async (user_id: string) => {
  const response = await fetch(AUTH_API_DOMAIN + '/v1/users/' + user_id + '/reactivate', {
    headers: generateRequestHeaders(),
    method: 'PUT',
  });
  return response.status === 204;
};

const removeUserMfa = async (user_id: string) => {
  const response = await fetch(AUTH_API_DOMAIN + '/v1/users/' + user_id + '/mfa-devices', {
    headers: generateRequestHeaders(),
    method: 'DELETE',
  });
  return response.status === 204;
};

export default {
  MFA_DEVICE_ID_APP,
  MFA_DEVICE_ID_SMS,
  signIn,
  logout,
  forgotPassword,
  resetPassword,
  checkScopes,
  createMFAAccessToken,
  mfaRegisterDevice,
  mfaVerifyDevice,
  mfaSignIn,
  getMe,
  getUserInfo,
  getUserRoles,
  updateUserRoles,
  reactivateUser,
  removeUserMfa,
  getSSOClients,
  getSSOClientBySlug,
  getSSOClient,
  createSSOClient,
  updateSSOClient,
  validateAccess,
  generateSSOClientSPEncryption,
  shouldLogoutBasedOnScopes,
};
