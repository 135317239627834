import { combineReducers } from 'redux';
import { form, modal, notifications, table } from '@spring/smeargle/reducers';

const combined = combineReducers({
  form,
  modal,
  table,
});

let actions = [];

const rootReducer = (state, action) => {
  if (actions.length > 100) {
    actions.shift();
  }

  actions.push(action);

  return combined(state, action);
};

const getActions = () => actions;

export { getActions };
export default rootReducer;
