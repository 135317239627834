//

export const INITIALIZE_FORM = 'form/INITIALIZE_FORM';
export const SET_FORM_FIELD = 'form/SET_FORM_FIELD';
export const SET_FORM_VALIDATION = 'form/SET_FORM_VALIDATION';
export const CLEAR_FORM = 'form/CLEAR_FORM';
export const REMOVE_FORM_FIELD = 'form/REMOVE_FORM_FIELD';
export const SET_DATEPICKER_DISPLAY_DATE = 'form/SET_DATEPICKER_DISPLAY_DATE';
export const SET_TYPEAHEAD_SEARCH_VALUE = 'form/SET_TYPEAHEAD_SEARCH_VALUE';
export const SET_ADDRESS_VALUE = 'form/SET_ADDRESS_VALUE';
